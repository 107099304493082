import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { applyMiddleware, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable';
import logger from 'redux-logger';
import Reducers from 'Redux/Reducer';
import Epics from 'Redux/Epic';

const persistConfig = {
    key: 'unitrend-portal-upload',
    storage,
    debug: true,

    whitelist: [],
};

const epicMiddleware = createEpicMiddleware({

});


export let Persistor;

export const rehydrateStore = storeToHydrate => new Promise((resolve, reject) => {
    Persistor = persistStore(storeToHydrate, null, () => {
        resolve();
    });
});

const middlewares = [
    epicMiddleware,
];

if(process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}

export const Store = createStore(
    persistCombineReducers(persistConfig, { ...Reducers }),
    applyMiddleware( ...middlewares ),
)


epicMiddleware.run(Epics);
