import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Suspense, useMemo } from 'react';
import LoadingMessage      from "Common/LoadingMessage/LoadingMessage";
import { publicRoutes }    from "AppConfig/Routes";
import { FallbackRoute } from './FallbackRoute';

export const Router = ({ children }) => {
    const renderFallBack = () => {
        return <LoadingMessage className="pageLoader"/>;
    };

    const routes = useMemo(
        () =>
            Object.keys(publicRoutes)
                .map((routeKey) => publicRoutes[routeKey]),
        [],
    );

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<FallbackRoute />} />
                {routes.map(({ path, Component }, index) => (
                    <Route
                        path={path}
                        key={`Route-${index}__${path}`}
                        element={
                            <Suspense fallback={renderFallBack()}>
                                <Component />
                            </Suspense>
                        }
                    />
                ))}
            </Routes>
            {children}
        </BrowserRouter>
    );
};
