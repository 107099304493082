import { lazy } from "react";

export const publicRoutes = {
    signin: {
        path: '/',
        Component: lazy(() => (import('Page/XmlUpload'))),
    },
    documentUpload: {
        path: '/document',
        Component: lazy(() => (import('Page/DocumentUpload'))),
    },


}
