import { Store, rehydrateStore } from 'Redux/Config';
import { applicationBootstrapped } from 'Redux/Action';

async function hydrateStore() {
    const state = Store.getState();
    if(state.hasOwnProperty('_persist') && state._persist.hasOwnProperty('rehydrated')) {
        if(state._persist.rehydrated && state.hasOwnProperty('global') && state.global.isInitialized){
            // here can be check for reload or other thinks
            return false;
        }
    }
    await rehydrateStore(Store);
    Store.dispatch(applicationBootstrapped());
    return true;
}

export default () =>
    new Promise( async () => {
        await hydrateStore();
    });

