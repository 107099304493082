import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { publicRoutes }    from "AppConfig/Routes";

export const FallbackRoute = () => {
  const navigation = useNavigate();

  useEffect(() => {
    navigation(Object.values(publicRoutes)[0].path);
  });

  return null;
};
