import GlobalState from 'Redux/Store/Global/GlobalState';

const initialState = GlobalState.getInitialState();

export const globalReducer = (state = initialState, action) => {

    switch (action.type) {


    default:
        return state;
    }
};
