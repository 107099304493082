import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { Persistor, Store } from "Redux/Config";
import { Router } from "Application/Router/Router";
import { PersistGate } from 'redux-persist/integration/react'
import Bootstrap from "Redux/Bootstrap";
import 'antd/dist/reset.css';
import 'Public/css/global.css';
import de_DE from 'antd/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

const Application = () => (
    <ConfigProvider locale={ de_DE }>
        <Provider store={ Store }>
            <PersistGate loading={ null } persistor={ Persistor }>
                <Router />
            </PersistGate>
        </Provider>
    </ConfigProvider>
)

Bootstrap()
    .then(() => Application())
    .catch(error => console.error(error));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Application />);

